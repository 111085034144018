import React from 'react';
import { Link } from 'react-router-dom';

const Imprint = () => (
  <div className="min-h-screen bg-[#0A0E17] text-[#4DEEEA] p-8">
    <div className="max-w-4xl mx-auto">
      <Link to="/" className="text-[#00FFFF] hover:text-[#4DEEEA] transition-colors duration-300 mb-4 inline-block">
        &larr; Back to Home
      </Link>
      <h1 className="text-4xl font-bold mb-8 text-[#00FFFF]">Imprint</h1>
      
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-[#00FFFF]">Company Information</h2>
        <p>DIB CAPITAL GmbH</p>
        <p>Grellgasse 3/4/35</p>
        <p>1210 Vienna</p>
        <p>Austria</p>
      </section>
      
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-[#00FFFF]">Business Purpose</h2>
        <p>Participation in other companies and management of own assets. Business consulting, especially business organization and provision of other services including consulting in connection with business strategy, sales, marketing and IT development.</p>
      </section>
      
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-[#00FFFF]">Company Details</h2>
        <p>VAT Number: ATU80076016</p>
        <p>Company Register Number: FN 616582 p</p>
        <p>Commercial Court: Handelsgericht Wien</p>
      </section>
      
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-[#00FFFF]">Contact</h2>
        <p>Email: office@dib.capital</p>
      </section>
      
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-[#00FFFF]">Membership</h2>
        <p>Member of: WKO</p>
        <p>Professional Law: Trade Regulations: <a href="http://www.ris.bka.gv.at" target="_blank" rel="noopener noreferrer" className="text-[#00FFFF] hover:underline">www.ris.bka.gv.at</a></p>
      </section>
      
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-[#00FFFF]">Management</h2>
        <p>Managing Director: Ing. Daniel Ibrahim</p>
      </section>
      
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-[#00FFFF]">Data Protection</h2>
        <p>If you have any questions about data protection, you can find the contact details of the person responsible below:</p>
        <p>Daniel Ibrahim</p>
        <p>Email: office@dib.capital</p>
      </section>
      
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-[#00FFFF]">Liability for Contents</h2>
        <p>As service providers, we are liable for own contents of these websites. However, service providers are not obligated to permanently monitor submitted or stored information or to search for evidences that indicate illegal activities.</p>
        <p>Legal obligations to removing information or to blocking the use of information remain unchallenged. In this case, liability is only possible at the time of knowledge about a specific violation of law. Illegal contents will be removed immediately at the time we get knowledge of them.</p>
      </section>
      
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-[#00FFFF]">Liability for Links</h2>
        <p>Our offer includes links to external third-party websites. We have no influence on the contents of those websites, therefore we cannot guarantee for those contents. Providers or administrators of linked websites are always responsible for their own contents.</p>
        <p>The linked websites had been checked for possible violations of law at the time of the establishment of the link. Illegal contents were not detected at the time of the linking. A permanent monitoring of the contents of linked websites cannot be imposed without reasonable indications that there has been a violation of law. Illegal links will be removed immediately at the time we get knowledge of them.</p>
      </section>
      
      <section>
        <h2 className="text-2xl font-semibold mb-4 text-[#00FFFF]">Copyright</h2>
        <p>Contents and compilations published on these websites by the providers are subject to German copyright laws. Reproduction, editing, distribution as well as the use of any kind outside the scope of the copyright law require a written permission of the author or originator. Downloads and copies of these websites are permitted for private use only.</p>
        <p>The commercial use of our contents without permission of the originator is prohibited.</p>
        <p>Copyright laws of third parties are respected as long as the contents on these websites do not originate from the provider. Contributions of third parties on this site are indicated as such. However, if you notice any violations of copyright law, please inform us. Such contents will be removed immediately.</p>
      </section>
    </div>
  </div>
);

export default Imprint;