import React from 'react';

export const Card = ({ children, className, ...props }) => (
  <div className={`rounded-lg shadow-md ${className}`} {...props}>
    {children}
  </div>
);

export const CardHeader = ({ children, ...props }) => (
  <div className="p-4" {...props}>
    {children}
  </div>
);

export const CardTitle = ({ children, ...props }) => (
  <h3 className="text-lg font-semibold" {...props}>
    {children}
  </h3>
);

export const CardContent = ({ children, ...props }) => (
  <div className="p-4" {...props}>
    {children}
  </div>
);