import React, { useState, useEffect } from 'react';
import { Sun, Moon, Menu, User, Briefcase, TrendingUp, Users, Target, Building2, Zap, Building, CreditCard, Heart, Cpu, Linkedin, Newspaper, ExternalLink, PlusCircle } from 'lucide-react';
import { Button } from './components/ui/button';
import { Card, CardHeader, CardTitle, CardContent } from './components/ui/card';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Imprint from './Imprint';
import danielImage from './images/danielImage.png'; // Add this line
import LoadingScreen from './LoadingScreen';

const services = [
  { title: 'Interim Management', description: 'Temporary leadership solutions for your business needs.', icon: <Users className="h-6 w-6" /> },
  { title: 'Product Management', description: 'Guiding product development from concept to launch.', icon: <Target className="h-6 w-6" /> },
  { title: 'Growth Marketing', description: 'Strategies to accelerate your business growth.', icon: <TrendingUp className="h-6 w-6" /> },
  { title: 'Leadership Coaching', description: 'Developing strong leaders for tomorrow.', icon: <User className="h-6 w-6" /> },
  { title: 'Operational Excellence', description: 'Optimizing your business processes for peak performance.', icon: <Briefcase className="h-6 w-6" /> },
  { title: 'Company Building', description: 'Comprehensive support in establishing and scaling your company.', icon: <Building2 className="h-6 w-6" /> }
];

const Navbar = ({ darkMode, toggleDarkMode }) => (
  <nav className="flex justify-between items-center p-4 bg-[#0A0E17] border-b border-[#1E2738] transition-all duration-300">
    <h1 className="text-2xl font-bold text-[#00FFFF] animate-pulse">DIB CAPITAL</h1>
    <div className="flex items-center space-x-4">
      <Button onClick={toggleDarkMode} variant="ghost" className="text-[#00FFFF] hover:bg-[#1E2738] transition-colors duration-300">
        {darkMode ? <Sun className="h-5 w-5" /> : <Moon className="h-5 w-5" />}
      </Button>
      <Button variant="ghost" className="text-[#00FFFF] hover:bg-[#1E2738] transition-colors duration-300"><Menu className="h-5 w-6" /></Button>
    </div>
  </nav>
);

const welcomeMessages = [
  'Welcome to ...',
  'Willkommen bei ...',
  'Bienvenue à ...',
  'Bienvenido a ...',
  'Benvenuti a...',
  '欢迎来到 ...',
  'ようこそ ...',
  'مرحبا بكم في...',
];

const Hero = () => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => 
        (prevIndex + 1) % welcomeMessages.length
      );
    }, 2500); // Change message every 2.5 seconds

    return () => clearInterval(intervalId);
  }, []);

  const currentMessage = welcomeMessages[currentMessageIndex];

  return (
    <section className="text-center py-20 bg-[#0A0E17] text-[#00FFFF] transition-all duration-300 animate-fade-in relative overflow-hidden">
      <div className="absolute inset-0 bg-grid-pattern opacity-10"></div>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 flex items-center justify-center">
        <div className="flex flex-col md:flex-row items-center md:space-x-4">
          <div className="w-full md:w-80 text-center md:text-right mb-4 md:mb-0">
            <h1 className="text-3xl md:text-4xl font-bold animate-typing">
              {currentMessage}
            </h1>
          </div>
          <div className="hidden md:block h-20 w-px bg-[#4DEEEA] opacity-50"></div>
          <div className="text-center md:text-left">
            <h1 className="text-4xl md:text-5xl font-bold">DIB CAPITAL</h1>
            <p className="text-lg md:text-xl mt-2 text-[#4DEEEA]">Empowering businesses through boutique consulting services</p>
          </div>
        </div>
      </div>
      <a 
        href="https://www.linkedin.com/in/danielib/" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="inline-block bg-[#4DEEEA] text-[#0A0E17] hover:bg-[#00FFFF] transition-all duration-300 transform hover:scale-105 relative z-10 px-8 py-3 text-lg mt-8 rounded-md"
      >
        Let's start building! <Zap className="ml-2 h-5 w-5 inline" />
      </a>
    </section>
  );
};
const CompaniesWorkedWith = () => (
  <section className="py-16 bg-[#0A0E17] transition-all duration-300 animate-fade-in">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <h2 className="text-3xl font-bold text-center mb-12 text-[#4DEEEA]">Companies We've Worked With</h2>
      <div className="flex flex-col lg:flex-row gap-12 items-start">
        <div className="w-full lg:w-1/2">
          <div className="grid grid-cols-2 md:grid-cols-3 gap-8 items-center justify-items-center">
            {[
              { icon: Building, name: 'ready2order', type: 'B2B SaaS' },
              { icon: CreditCard, name: 'vivid money', type: 'FinTech' },
              { icon: Heart, name: 'HEARTFELT_', type: 'Venture Capital' },
              { icon: Briefcase, name: 'dventures Group', type: 'Company Building' },
              { icon: Cpu, name: 'Synatix', type: 'Company Building' },
              { 
                icon: Linkedin, 
                name: 'Get in Touch', 
                type: 'Your Next Project', 
                cta: true,
                link: 'https://www.linkedin.com/in/danielib/'
              },
            ].map((company, index) => (
              <div key={index} className="flex flex-col items-center group">
                <a 
                  href={company.link} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className={`relative p-4 rounded-full ${company.cta ? 'bg-[#00FFFF]' : 'bg-[#1E2738]'} transition-all duration-300 group-hover:bg-[#2A3A5A] group-hover:shadow-[0_0_15px_rgba(0,255,255,0.3)]`}
                >
                  <company.icon className={`h-12 w-12 ${company.cta ? 'text-[#0A0E17]' : 'text-[#00FFFF]'} transition-all duration-300 group-hover:text-[#4DEEEA] group-hover:scale-110`} />
                </a>
                <p className="mt-2 text-[#4DEEEA] text-center text-sm transition-all duration-300 group-hover:text-[#00FFFF]">
                  {company.name}<br/>
                  <span className="text-xs opacity-75">({company.type})</span>
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="w-full lg:w-1/2 bg-[#1E2738] p-8 rounded-lg shadow-lg">
          <h3 className="text-2xl font-bold mb-4 text-[#00FFFF]">Proven Expertise</h3>
          <p className="text-[#4DEEEA] mb-4">
            With over 8 years of comprehensive experience, I've cultivated deep expertise across Strategy, Product Management, Growth Optimization, Leadership, and Start-Up Ecosystems.
          </p>
          <p className="text-[#4DEEEA]">
            My professional journey has been dedicated to developing and implementing transformative solutions that drive competitive advantage and sustainable growth. This wealth of experience enables me to navigate complex business landscapes and deliver results that consistently exceed expectations.
          </p>
        </div>
      </div>
    </div>
  </section>
);

const Services = () => (
  <section className="py-16 bg-[#0A0E17] transition-all duration-300 animate-fade-in relative">
    <div className="absolute inset-0 bg-circuit-pattern opacity-5"></div>
    <h2 className="text-3xl font-bold text-center mb-10 text-[#4DEEEA]">Our Services</h2>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 px-8 relative z-10 max-w-7xl mx-auto">
      {services.map((service, index) => (
        <Card key={index} className="bg-[#1E2738] border border-[#4DEEEA] hover:border-[#00FFFF] transition-all duration-300 transform hover:scale-105 hover:shadow-[0_0_15px_rgba(0,255,255,0.5)]">
          <CardHeader className="p-6">
            <CardTitle className="flex items-center gap-3 text-[#00FFFF] text-xl">
              {service.icon}
              <span>{service.title}</span>
            </CardTitle>
          </CardHeader>
          <CardContent className="p-6 pt-0">
            <p className="text-[#4DEEEA]">{service.description}</p>
          </CardContent>
        </Card>
      ))}
    </div>
  </section>
);

const AboutFounder = () => (
  <section className="py-20 bg-[#0A0E17] transition-all duration-300 animate-fade-in relative">
    <div className="absolute inset-0 bg-binary-pattern opacity-5"></div>
    <h2 className="text-3xl font-bold text-center mb-10 text-[#4DEEEA]">About the Founder</h2>
    <div className="max-w-4xl mx-auto px-8 relative z-10">
      <div className="flex flex-col md:flex-row items-center md:items-start gap-8">
        <div className="w-48 h-48 rounded-full overflow-hidden border-2 border-[#00FFFF] shadow-lg shadow-[#00FFFF]/20">
          <img src={danielImage} alt="Daniel IBRAHIM" className="w-full h-full object-cover" />
        </div>
        <div className="flex-1">
          <h3 className="text-2xl font-semibold mb-1 text-[#00FFFF] text-center md:text-left">Daniel IBRAHIM</h3>
          <p className="text-lg text-[#4DEEEA] mb-4 text-center md:text-left italic">Founder and Managing Director</p>
          <p className="text-[#4DEEEA] mb-4 text-center md:text-left">
            With over 8 years of extensive experience in Strategy, Product Management, Growth Marketing, Leadership Development, and Start-Up Ecosystems, Daniel is a seasoned entrepreneur and consultant dedicated to driving business excellence and innovation.
          </p>
          <p className="text-[#4DEEEA] mb-4 text-center md:text-left">
            Daniel specializes in helping companies optimize their planning, processes, products, and services. His core focus areas include organizational design, product discovery, strategic planning, and execution. He has cultivated deep expertise in SaaS, FinTech, and E-Commerce sectors, positioning him at the forefront of digital transformation strategies.
          </p>
          <p className="text-[#4DEEEA] mb-4 text-center md:text-left">
            With a proven track record in team management and project coordination, Daniel consistently delivers outcome-oriented growth and operational excellence. His ability to successfully manage diverse teams and juggle multiple projects simultaneously has been instrumental in driving transformative results for numerous organizations.
          </p>
          <p className="text-[#4DEEEA] text-center md:text-left">
            As the founder of DIB CAPITAL, Daniel leverages his multifaceted expertise to provide transformative solutions that ensure businesses stay ahead in today's rapidly evolving market landscape.
          </p>
        </div>
      </div>
    </div>
  </section>
);

const FeaturedProjects = () => {
  const [hoveredLink, setHoveredLink] = useState(null);

  const projects = [
    {
      title: "ready2order: Mobile POS System",
      description: "Launched a mobile point-of-sale system with integrated payment function, addressing the needs of merchants, gastronomes, and service providers for efficient process management and contactless payments.",
      pressMention: "As featured in Brutkasten",
      pressLink: "https://brutkasten.com/artikel/ready2order-startup-launcht-mobile-kassensystem-mit-integrierter-payment-funktion"
    },
    {
      title: "Vivid: Business Banking for Freelancers",
      description: "Expanded product range to include business banking for freelancers and self-employed individuals, offering a unified app for both personal and business finances with features like business pockets and exclusive Platinum Business VISA card.",
      pressMention: "As announced in Vivid Press Release",
      pressLink: "https://press.vivid.money/press-releases-feed/vivid-startet-business-banking-fur-selbststandige"
    }
  ];

  return (
    <section className="py-20 bg-[#1E2738] transition-all duration-300 animate-fade-in relative">
      <div className="absolute inset-0 bg-circuit-pattern opacity-5"></div>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center mb-12 text-[#4DEEEA]">Featured Projects</h2>
        <div className="grid md:grid-cols-2 gap-12">
          {projects.map((project, index) => (
            <div key={index} className="bg-[#0A0E17] rounded-lg overflow-hidden shadow-lg transition-all duration-300 hover:shadow-[0_0_15px_rgba(0,255,255,0.3)]">
              <div className="p-6">
                <h3 className="text-xl font-semibold mb-2 text-[#00FFFF]">{project.title}</h3>
                <p className="text-[#4DEEEA] mb-4">{project.description}</p>
                <a 
                  href={project.pressLink} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="text-sm flex items-center group"
                  style={{ 
                    color: hoveredLink === `press-${index}` ? '#4DEEEA' : '#00FFFF',
                    transition: 'all 0.3s ease'
                  }}
                  onMouseEnter={() => setHoveredLink(`press-${index}`)}
                  onMouseLeave={() => setHoveredLink(null)}
                >
                  <Newspaper className="mr-2 h-4 w-4 transition-transform duration-300 group-hover:rotate-12" />
                  <span className="relative">
                    {project.pressMention}
                    <span className="absolute left-0 bottom-0 w-full h-0.5 bg-[#4DEEEA] transform scale-x-0 transition-transform duration-300 origin-left group-hover:scale-x-100"></span>
                  </span>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const GetInTouch = () => (
  <section className="py-16 bg-[#1E2738] transition-all duration-300 animate-fade-in relative">
    <div className="absolute inset-0 bg-circuit-pattern opacity-5"></div>
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 text-center relative z-10">
      <h2 className="text-3xl font-bold mb-6 text-[#4DEEEA]">Ready to Transform Your Business?</h2>
      <p className="text-xl mb-8 text-[#00FFFF]">Let's connect and explore how we can drive your company's growth and innovation.</p>
      <a 
        href="https://www.linkedin.com/in/danielib/" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="inline-flex items-center px-8 py-3 text-lg font-semibold text-[#0A0E17] bg-[#4DEEEA] rounded-md hover:bg-[#00FFFF] transition-all duration-300 transform hover:scale-105"
      >
        <Linkedin className="mr-2 h-5 w-5" />
        Connect on LinkedIn
      </a>
    </div>
  </section>
);

const Divider = () => (
  <div className="py-8 bg-[#0A0E17]">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="h-px bg-gradient-to-r from-transparent via-[#00FFFF] to-transparent"></div>
    </div>
  </div>
);

const Footer = () => (
  <footer className="bg-[#0A0E17] border-t border-[#1E2738] py-8 transition-all duration-300">
    <div className="container mx-auto px-4 text-center text-[#4DEEEA]">
      <p>&copy; 2024 DIB CAPITAL. All rights reserved.</p>
      <Link to="/imprint" className="text-[#00FFFF] hover:text-[#4DEEEA] transition-colors duration-300 mt-2 inline-block transform hover:scale-105">
        Imprint
      </Link>
    </div>
  </footer>
);

const MainContent = () => {
  return (
    <div>
      <Navbar />
      <Hero />
      <CompaniesWorkedWith />
      <Divider />
      <Services />
      <FeaturedProjects />
      <AboutFounder />
      <GetInTouch />
      <Footer />
    </div>
  );
};

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading time
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000); // 3 seconds loading time, adjust as needed

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainContent />} />
        <Route path="/imprint" element={<Imprint />} />
        <Route path="*" element={<MainContent />} />
      </Routes>
    </Router>
  );
};

export default App;